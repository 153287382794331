exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blogindex-jsx": () => import("./../../../src/pages/blogindex.jsx" /* webpackChunkName: "component---src-pages-blogindex-jsx" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iso-certification-js": () => import("./../../../src/pages/iso-certification.js" /* webpackChunkName: "component---src-pages-iso-certification-js" */),
  "component---src-pages-leadership-js": () => import("./../../../src/pages/leadership.js" /* webpackChunkName: "component---src-pages-leadership-js" */),
  "component---src-pages-newsroom-js": () => import("./../../../src/pages/newsroom.js" /* webpackChunkName: "component---src-pages-newsroom-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-cloud-native-solutions-index-js": () => import("./../../../src/pages/services/cloud-native-solutions/index.js" /* webpackChunkName: "component---src-pages-services-cloud-native-solutions-index-js" */),
  "component---src-pages-services-custom-software-development-index-js": () => import("./../../../src/pages/services/custom-software-development/index.js" /* webpackChunkName: "component---src-pages-services-custom-software-development-index-js" */),
  "component---src-pages-services-data-engineering-index-js": () => import("./../../../src/pages/services/data-engineering/index.js" /* webpackChunkName: "component---src-pages-services-data-engineering-index-js" */),
  "component---src-pages-services-devsecops-index-js": () => import("./../../../src/pages/services/devsecops/index.js" /* webpackChunkName: "component---src-pages-services-devsecops-index-js" */),
  "component---src-pages-services-it-consulting-index-js": () => import("./../../../src/pages/services/it-consulting/index.js" /* webpackChunkName: "component---src-pages-services-it-consulting-index-js" */),
  "component---src-pages-services-mobile-app-development-index-js": () => import("./../../../src/pages/services/mobile-app-development/index.js" /* webpackChunkName: "component---src-pages-services-mobile-app-development-index-js" */),
  "component---src-pages-services-resource-augmentation-ai-engineer-js": () => import("./../../../src/pages/services/resource-augmentation/ai-engineer.js" /* webpackChunkName: "component---src-pages-services-resource-augmentation-ai-engineer-js" */),
  "component---src-pages-services-resource-augmentation-backend-developer-js": () => import("./../../../src/pages/services/resource-augmentation/backend-developer.js" /* webpackChunkName: "component---src-pages-services-resource-augmentation-backend-developer-js" */),
  "component---src-pages-services-resource-augmentation-cloud-engineer-js": () => import("./../../../src/pages/services/resource-augmentation/cloud-engineer.js" /* webpackChunkName: "component---src-pages-services-resource-augmentation-cloud-engineer-js" */),
  "component---src-pages-services-resource-augmentation-database-engineer-js": () => import("./../../../src/pages/services/resource-augmentation/database-engineer.js" /* webpackChunkName: "component---src-pages-services-resource-augmentation-database-engineer-js" */),
  "component---src-pages-services-resource-augmentation-frontend-developer-js": () => import("./../../../src/pages/services/resource-augmentation/frontend-developer.js" /* webpackChunkName: "component---src-pages-services-resource-augmentation-frontend-developer-js" */),
  "component---src-pages-services-resource-augmentation-full-stack-developer-js": () => import("./../../../src/pages/services/resource-augmentation/full-stack-developer.js" /* webpackChunkName: "component---src-pages-services-resource-augmentation-full-stack-developer-js" */),
  "component---src-pages-services-resource-augmentation-index-js": () => import("./../../../src/pages/services/resource-augmentation/index.js" /* webpackChunkName: "component---src-pages-services-resource-augmentation-index-js" */),
  "component---src-pages-services-resource-augmentation-mobile-app-developer-js": () => import("./../../../src/pages/services/resource-augmentation/mobile-app-developer.js" /* webpackChunkName: "component---src-pages-services-resource-augmentation-mobile-app-developer-js" */),
  "component---src-pages-services-software-engineering-index-js": () => import("./../../../src/pages/services/software-engineering/index.js" /* webpackChunkName: "component---src-pages-services-software-engineering-index-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

